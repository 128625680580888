
.header {
  background-color: #e20613;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
}

.logo {  
  max-width: 320px;
}

.message {  
  text-align: left;
  max-width: 320px;
  margin: auto;
  padding: 10px;
}
